/* global axios */
import ApiClient from './ApiClient';

const getTimeOffset = () => -new Date().getTimezoneOffset() / 60;

class ReportsAPI extends ApiClient {
  constructor() {
    super('reports', { accountScoped: true, apiVersion: 'v2' });
  }

  getReports({
    metric,
    from,
    to,
    type = 'account',
    id,
    groupBy,
    businessHours,
    team,
  }) {
    return axios.get(`${this.url}`, {
      params: {
        metric,
        since: from,
        until: to,
        type,
        id,
        group_by: groupBy,
        business_hours: businessHours,
        timezone_offset: getTimeOffset(),
        team_id: team?.id,
      },
    });
  }

  // eslint-disable-next-line default-param-last
  getSummary(
    since,
    until,
    id,
    teamId,
    groupBy,
    businessHours,
    type = 'account'
  ) {
    return axios.get(`${this.url}/summary`, {
      params: {
        since,
        until,
        type,
        id,
        team_id: teamId,
        group_by: groupBy,
        business_hours: businessHours,
        timezone_offset: getTimeOffset(),
      },
    });
  }

  getConversationMetric({
    type = 'account',
    page = 1,
    from: since,
    to: until,
    teamId,
  }) {
    return axios.get(`${this.url}/conversations`, {
      params: {
        type,
        page,
        since,
        until,
        team_id: teamId,
      },
    });
  }

  getContactMetric({
    type = 'account',
    page = 1,
    from: since,
    to: until,
    teamId,
  }) {
    return axios.get(`${this.url}/contacts`, {
      params: {
        type,
        page,
        since,
        until,
        team_id: teamId,
      },
    });
  }

  getAgentReports({ from: since, to: until, businessHours }) {
    return axios.get(`${this.url}/agents`, {
      params: { since, until, business_hours: businessHours },
    });
  }

  getConversationTrafficCSV() {
    return axios.get(`${this.url}/conversation_traffic`, {
      params: { timezone_offset: getTimeOffset() },
    });
  }

  getLabelReports({ from: since, to: until, businessHours }) {
    return axios.get(`${this.url}/labels`, {
      params: { since, until, business_hours: businessHours },
    });
  }

  getInboxReports({ from: since, to: until, businessHours }) {
    return axios.get(`${this.url}/inboxes`, {
      params: { since, until, business_hours: businessHours },
    });
  }

  getTeamReports({ from: since, to: until, businessHours }) {
    return axios.get(`${this.url}/teams`, {
      params: { since, until, business_hours: businessHours },
    });
  }

  getBotMetrics({ from, to } = {}) {
    return axios.get(`${this.url}/bot_metrics`, {
      params: { since: from, until: to },
    });
  }

  getBotSummary({ from, to, groupBy, businessHours } = {}) {
    return axios.get(`${this.url}/bot_summary`, {
      params: {
        since: from,
        until: to,
        type: 'account',
        group_by: groupBy,
        business_hours: businessHours,
      },
    });
  }

  getAgentPlannedMetrics() {
    return axios.get(`${this.url}/agent_planned_metrics`);
  }

  getAgentContactsMetrics() {
    return axios.get(`${this.url}/agent_contacts_metrics`);
  }

  getAgentConversationsMetrics() {
    return axios.get(`${this.url}/agent_conversations_metrics`);
  }

  getAgentPlannedConversations() {
    return axios.get(`${this.url}/agent_planned_conversations`);
  }

  getConversionMetric({
    from: since,
    to: until,
    page = 1,
    criteria_type = 'inbox',
    teamId,
  }) {
    return axios.get(`${this.url}/conversions`, {
      params: {
        since,
        until,
        page,
        criteria_type,
        team_id: teamId,
      },
    });
  }
}

export default new ReportsAPI();
